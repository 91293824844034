import { format } from "date-fns";

import { ISO8601_DATE_FORMAT } from "@constants";
import {
  ILoopbackFilter,
  Operators,
} from "../../../../modules/filter/shared/interfaces/loopback";
import { IPaginationState } from "../../../../modules/filter/shared/interfaces/pagination-types";
import { loopBackFilterAdapter } from "../../../../modules/filter/shared/utils/loopback-filter-adapter";
import { ITechOpListFilter } from "../../shared/interfaces/tech-operations-state";
import { OrderGroup } from "../../../../shared/interface";

export type FetchTechOpListArgs = {
  paginationState: IPaginationState;
  filtersState: ITechOpListFilter;
};

export type TechOpsRequest = FetchTechOpListArgs & {
  seasonId: string;
  farmId: string;
  onlyOriginal: number;
};

export type DateRange = {
  from: Date;
  to: Date;
};

export type LoopbackTechOpFilter = {
  limit: number;
  offset: number;
  order: OrderGroup;
  startDate: string;
  finishDate: string;
  seasonId: string | null;
  farmId: string | null;
  farmLandId?: string | undefined;
  cropTypeId: string | undefined;
  techOperationGroupId: string | undefined;
  techOperationSubGroupId: string | undefined;
};

export function getCurrentFilter({
  paginationState,
  filtersState,
  seasonId,
  farmId,
}: FetchTechOpListArgs & {
  seasonId: string;
  farmId: string;
}): ILoopbackFilter<LoopbackTechOpFilter> {
  const backendFilter = loopBackFilterAdapter<LoopbackTechOpFilter>(
    {
      limit: paginationState.limit,
      offset: paginationState.offset,
      order: paginationState.order,
      startDate: format(filtersState.from, ISO8601_DATE_FORMAT),
      finishDate: format(filtersState.to, ISO8601_DATE_FORMAT),
      seasonId: seasonId,
      farmId: farmId,
      ...(Boolean(filtersState.farmLandId) && { farmLandId: filtersState.farmLandId }),
      cropTypeId: filtersState.cropTypeId,
      techOperationGroupId: filtersState.groupId,
      techOperationSubGroupId: filtersState.subGroupId,
    },
    {
      where: {
        and: [
          { seasonId: "eq" },
          { farmId: "eq" },
          { farmLandId: "eq" },
          { startDate: ["lte", format(filtersState.to, ISO8601_DATE_FORMAT)] },
          { finishDate: ["gte", format(filtersState.from, ISO8601_DATE_FORMAT)] },
          { cropTypeId: "eq" },
          { techOperationGroupId: "eq" },
          { techOperationSubGroupId: "eq" },
          ...(filtersState.farmLandId !== undefined
            ? [{ farmLandId: "eq" as Operators }]
            : []),
        ],
      },
      optional: ["cropTypeId", "techOperationGroupId", "techOperationSubGroupId"],
    }
  );
  return backendFilter;
}
