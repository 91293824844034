import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import { isEqual } from "lodash";

import {
  TObservationsFilter,
  IObservationsState,
} from "../shared/interfaces/observations-state";
import { Observation } from "../shared/models/observation";
import { observationsService } from "../shared/services/observations.service";

import { IPaginationState } from "../../../modules/filter/shared/interfaces/pagination-types";
import { Order } from "../../../shared/enums/order";
import { OrderGroup } from "../../../shared/interface";

export const observationDateAsc: OrderGroup = [["observationDate", Order.ASC]];
export const observationDateDesc: OrderGroup = [["observationDate", Order.DESC]];

const initialPagination: IPaginationState = {
  limit: 10,
  page: 0,
  order: observationDateDesc,
  offset: 0,
};

const initialState: IObservationsState = {
  isLoading: false,
  list: [],
  listCount: 0,
  filter: {
    from: startOfDay(startOfMonth(new Date())),
    to: endOfDay(new Date()),
    farmLandId: undefined, // undefined => NOTHING_SELECTED_IN_FILTER_DROPDOWN
    cropType: undefined, // undefined => NOTHING_SELECTED_IN_FILTER_DROPDOWN
    phenoPhaseId: undefined, // undefined => NOTHING_SELECTED_IN_FILTER_DROPDOWN
  },
  pagination: initialPagination,
};

export const OBSERVATIONS_MODULE_NAME = "observations";
export const observationsSlice = createSlice({
  name: OBSERVATIONS_MODULE_NAME,
  initialState: initialState,
  reducers: {
    setFilterAction(state, action: PayloadAction<TObservationsFilter>): void {
      state.filter = action.payload;
    },
    setFilterProperty(state, action: PayloadAction<Partial<TObservationsFilter>>): void {
      const filterMerged: TObservationsFilter = { ...state.filter, ...action.payload };
      if (!isEqual(filterMerged, state)) {
        state.filter = filterMerged;
      }
    },
    setPaginationAction(state, action: PayloadAction<IPaginationState>): void {
      state.pagination = action.payload;
    },
    setPaginationProperty(state, action: PayloadAction<Partial<IPaginationState>>): void {
      const paginationMerged: IPaginationState = {
        ...state.pagination,
        ...action.payload,
      };
      paginationMerged.offset = paginationMerged.limit * paginationMerged.page;
      if (!isEqual(paginationMerged, state)) {
        state.pagination = paginationMerged;
      }
    },
    setListAction(state, action: PayloadAction<Observation[]>): void {
      state.list = action.payload;
    },
    setListCountAction(state, action: PayloadAction<number>): void {
      state.listCount = action.payload;
    },
    removeItemFromListAction(state, action: PayloadAction<string>): void {
      const index = state.list.findIndex((item) => item.id === action.payload);

      if (index === -1) {
        return;
      }
      state.list.splice(index, 1);
    },
    decrementListCountAction(state): void {
      state.listCount--;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(removeObservationAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(removeObservationAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(removeObservationAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const observationsReducer = observationsSlice.reducer;
export const {
  setListAction,
  setListCountAction,
  removeItemFromListAction,
  decrementListCountAction,
  setFilterAction,
  setFilterProperty,
  setPaginationAction,
  setPaginationProperty,
} = observationsSlice.actions;

export const removeObservationAction = createAsyncThunk<void, string>(
  `${OBSERVATIONS_MODULE_NAME}/removeObservationAction`,
  async (id: string, { dispatch }) => {
    await observationsService.delete(id);
    dispatch(removeItemFromListAction(id));
    dispatch(decrementListCountAction());
  }
);
