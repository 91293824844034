import { Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

export type AutoHide = {
  autoHideMessage: string | undefined;
  isError: boolean | undefined;
  autoHideJsx: JSX.Element | undefined;
  autoHide: (msg: string, isError?: boolean, timeout?: number) => void;
};

export function useAutoHide(): AutoHide {
  const [autoHideMessage, setAutoHideMessage] = useState<string>();
  const [isError, setIsError] = useState<boolean>();

  const autoHide = useCallback(
    (msg: string, thisIsAnError = false, timeout = 5000) => {
      setAutoHideMessage(msg);
      setIsError(thisIsAnError);
      setTimeout(() => {
        setAutoHideMessage(undefined);
        setIsError(undefined);
      }, timeout);
    },
    [setAutoHideMessage, setIsError]
  );

  const autoHideJsx: JSX.Element | undefined = useMemo(
    () => colorifyError(autoHideMessage, isError),
    [isError, autoHideMessage]
  );

  return {
    autoHideMessage,
    isError,
    autoHideJsx,
    autoHide,
  };
}

export function colorifyError(
  autoHideMessage?: string,
  isError?: boolean
): JSX.Element | undefined {
  if (autoHideMessage === undefined) {
    return undefined;
  }

  const color = isError === undefined ? "inherited" : isError ? "red" : "green";

  // https://stackoverflow.com/questions/50211983/how-to-make-material-ui-typography-without-newline
  return (
    <Typography
      component="span"
      // display="inline"
      sx={{ color: color }}
    >
      {autoHideMessage}
    </Typography>
  );
}
