import { useState } from "react";
import { UseFormReset } from "react-hook-form";

import { useAutoHide } from "@hooks";
import { TIME_TO_HIDE_ERRORS } from "src/authentication/shared/constants";
import { IChangeOwnPasswordForm } from "src/authentication/shared/interfaces/auth-form";
import { updateOwnPassword } from "src/authentication/store/auth.slice";
import { addNotificationAction } from "src/modules/notifications";
import { NotificationSeverity } from "src/modules/notifications/shared/enums/notification-severity";
import { NotificationType } from "src/modules/notifications/shared/enums/notification-type";
import { IErrorResponseDto } from "src/shared/dtos/error-response.dto";
import { useAppDispatch } from "src/store";

export const usePassSubmit = (reset: UseFormReset<IChangeOwnPasswordForm>) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { autoHideMessage, autoHide } = useAutoHide();

  const onSubmit = async (data: IChangeOwnPasswordForm) => {
    const { oldPassword, newPassword } = data;

    try {
      setLoading(true);

      await dispatch(updateOwnPassword({ oldPassword, newPassword })).unwrap();

      dispatch(
        addNotificationAction({
          type: NotificationType.Snack,
          severity: NotificationSeverity.Success,
          message: "Пароль успешно изменен",
        })
      );
    } catch (ex) {
      const restApiError = (ex as IErrorResponseDto).error;
      if (restApiError) {
        let msg = restApiError.message;
        switch (restApiError.code) {
          case "INVALID_PASSWORD":
            msg = "Неверный старый пароль";
            break;
        }

        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Error,
            message: msg,
            error: ex,
          })
        );

        autoHide(msg, true, TIME_TO_HIDE_ERRORS);
      }
    } finally {
      setLoading(false);
    }
    reset(undefined, { keepDirty: true });
  };

  return { isLoading, autoHideMessage, onSubmit };
};
