import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "./authentication/containers/protected-route/protected-route";
import { Settings } from "./pages/settings/containers/settings/settings";
import { PATHS } from "./constants";

const AssetUsageRecords = lazy(
  () =>
    import(
      "./pages/asset-usage-records/containers/asset-usage-records/asset-usage-records"
    )
);
const Login = lazy(() => import("./pages/login/containers/login/login"));
const FarmLands = lazy(() => import("./pages/fields/containers/farm-lands/farm-lands"));
const Catalogs = lazy(() => import("./pages/catalogs/catalogs"));
const Dashboard = lazy(() => import("./pages/dashboard/containers/dashboard/dashboard"));
const MapComponent = lazy(() => import("./pages/map-page/containers/map/map"));
const Observations = lazy(
  () => import("./pages/observations/containers/observations/observations")
);
const TechOperations = lazy(
  () => import("./pages/tech-operations/containers/tech-operations/tech-operations")
);

export const AppRouter = (): JSX.Element => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path={`${PATHS.LOGIN_PAGE}/*`} element={<Login />} />
        <Route
          path={PATHS.MAIN_PAGE}
          element={<ProtectedRoute urlWhenUserFetchFailed={PATHS.LOGIN_PAGE} />}
        >
          <Route path={`${PATHS.CATALOG_PAGE}/*`} element={<Catalogs />} />
          <Route path={`${PATHS.MAP_PAGE}/*`} element={<MapComponent />} />
          <Route path={`${PATHS.TECHOPERATIONS_PAGE}/*`} element={<TechOperations />} />
          <Route path={`${PATHS.FIELDS_PAGE}/*`} element={<FarmLands />} />
          <Route path={`${PATHS.OBSERVATIONS_PAGE}/*`} element={<Observations />} />
          <Route path={`${PATHS.DASHBOARD}/*`} element={<Dashboard />} />
          <Route path={`${PATHS.SETTINGS}/*`} element={<Settings />} />
          <Route
            path={`${PATHS.ASSET_USAGE_RECORDS}/*`}
            element={<AssetUsageRecords />}
          />
          <Route index={true} element={<Navigate to={PATHS.MAP_PAGE} replace={true} />} />
          <Route path={"*"} element={<Navigate to={PATHS.MAP_PAGE} replace={true} />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
