import { format } from "date-fns";
import { HUMAN_DATE_FORMAT, ISO8601_DATE_FORMAT } from "../../constants";

// TODO: maybe its better to remove it in favor of direct format use
export function formatDate(date: Date, dateFormat: string = ISO8601_DATE_FORMAT): string {
  return format(date, dateFormat);
}

export function formatDateRange(
  startDate: Date,
  finishDate: Date,
  dateFormat: string = HUMAN_DATE_FORMAT
): string {
  const startDateFormatted = formatDate(startDate, dateFormat);
  const finishDateFormatted = formatDate(finishDate, dateFormat);
  return `${startDateFormatted} – ${finishDateFormatted}`;
}

export function formatDateRangeStr(
  startDateStr: string,
  finishDateStr: string,
  dateFormat: string = HUMAN_DATE_FORMAT
): string {
  const startDate = new Date(startDateStr);
  const finishDate = new Date(finishDateStr);
  return formatDateRange(startDate, finishDate, dateFormat);
}
