import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { ISO8601_DATETIME_FORMAT, ISO8601_DATE_FORMAT } from "@constants";
import { ProductionType } from "../../../../shared/enums";
import { ITechOperationDto } from "../dtos/tech-operation.dto";
import { ITechOperationFormData } from "../interfaces/tech-operation-form-data";
import { TechOperationAsset } from "./tech-operation-asset";
import { TechOperationValue } from "./tech-operation-value";

export class TechOperation {
  readonly id: string;
  farmId: string;
  farmLandId: string;
  farmLandName: string;
  // release/2.1 hotfix for DEVELOPMENT-3626, DEVELOPMENT-3628
  Real_farmLandId: string;
  Real_farmLandName: string;
  cropTypeId: string;
  cropId?: string;
  startDate = new Date();
  finishDate = new Date();
  techOperationGroupId: string;
  techOperationSubGroupId: string;
  fieldSize: number;
  operationNumber: number;
  comment: string;
  productionType: ProductionType;
  techAssetId: string | null;
  techAssetName: string;
  trailerAssetId: string | null;
  trailerAssetName: string | null;
  employeeId: string;
  seasonId?: string;
  massCleanedProduct?: number;
  productivitySet?: number;
  createdOnClientAt: string | null;
  appUserId: string;
  TechOperationValues: TechOperationValue[];
  materiallyAccountableEmployees: ITechOperationDto["materiallyAccountableEmployees"];
  Asset: TechOperationAsset[];

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  static fromDto(dto: ITechOperationDto): TechOperation {
    const model = new TechOperation(dto.id);
    model.updateFromDto(dto);
    return model;
  }

  get isPredecessor(): boolean {
    return this.Real_farmLandId !== undefined
      ? this.Real_farmLandId !== this.farmLandId
      : false;
  }

  get isOriginal(): boolean {
    return this.Real_farmLandId !== undefined
      ? this.Real_farmLandId === this.farmLandId
      : true;
  }

  get asDto(): ITechOperationDto {
    return {
      id: this.id,
      farmId: this.farmId,
      seasonId: this.seasonId,
      farmLandId: this.farmLandId,
      farmLandName: this.farmLandName,
      Real_farmLandId: this.Real_farmLandId,
      Real_farmLandName: this.Real_farmLandName,
      cropTypeId: this.cropTypeId,
      cropId: this.cropId,
      startDate: format(this.startDate, ISO8601_DATE_FORMAT),
      finishDate: format(this.finishDate, ISO8601_DATE_FORMAT),
      techOperationGroupId: this.techOperationGroupId,
      techOperationSubGroupId: this.techOperationSubGroupId,
      fieldSize: this.fieldSize,
      operationNumber: this.operationNumber,
      comment: this.comment,
      productionType: this.productionType,
      techAssetId: this.techAssetId,
      techAssetName: this.techAssetName,
      trailerAssetId: this.trailerAssetId === "" ? null : this.trailerAssetId,
      trailerAssetName:
        this.trailerAssetName === "" ? undefined : (this.trailerAssetName ?? undefined),
      employeeId: this.employeeId === "" ? null : this.employeeId,
      massCleanedProduct: this.massCleanedProduct,
      productivitySet: this.productivitySet,
      createdOnClientAt: this.createdOnClientAt,
      appUserId: this.appUserId,
      materiallyAccountableEmployees: this.materiallyAccountableEmployees,
      TechOperationValues: this.TechOperationValues?.map((item) => item.asDto),
      Asset: this.Asset?.map((asset) => asset.asDto),
    };
  }

  updateFromDto(dto: ITechOperationDto): void {
    this.farmId = dto.farmId;
    this.seasonId = dto.seasonId;
    this.farmLandId = dto.farmLandId;
    this.farmLandName = dto.farmLandName;

    this.Real_farmLandId = dto.Real_farmLandId;
    this.Real_farmLandName = dto.Real_farmLandName;

    this.cropTypeId = dto.cropTypeId;

    this.startDate = new Date(dto.startDate);
    this.finishDate = new Date(dto.finishDate);
    this.techOperationGroupId = dto.techOperationGroupId;
    this.techOperationSubGroupId = dto.techOperationSubGroupId;
    this.fieldSize = dto.fieldSize;
    this.operationNumber = dto.operationNumber;
    this.comment = dto.comment;

    this.productionType = dto.productionType;
    this.techAssetId = dto.techAssetId;
    this.trailerAssetId = dto.trailerAssetId;
    this.employeeId = dto.employeeId === null ? "" : dto.employeeId;

    this.massCleanedProduct = dto.massCleanedProduct;
    this.productivitySet = dto.productivitySet;
    this.createdOnClientAt = dto.createdOnClientAt;

    this.appUserId = dto.appUserId;

    this.TechOperationValues =
      dto.TechOperationValues?.map(TechOperationValue.fromDto) || [];
    this.materiallyAccountableEmployees = dto.materiallyAccountableEmployees;
    this.Asset =
      dto.Asset?.map((assetDto) => {
        const model = TechOperationAsset.fromDto(assetDto);
        return model;
      }) || [];
  }

  get asFormData(): ITechOperationFormData {
    return {
      id: this.id,
      farmId: this.farmId || "",
      seasonId: this.seasonId || "",
      farmLandId: this.farmLandId || "",
      cropTypeId: this.cropTypeId || "",
      cropId: this.cropId,
      startDate: new Date(this.startDate.getTime()),
      finishDate: new Date(this.finishDate.getTime()),
      techOperationGroupId: this.techOperationGroupId || "",
      techOperationSubGroupId: this.techOperationSubGroupId || "",
      fieldSize:
        this.fieldSize !== undefined && this.fieldSize !== null ? this.fieldSize : null,
      // operationNumber: this.operationNumber || "",
      operationNumber: this.operationNumber !== undefined ? this.operationNumber : "",
      comment: this.comment || "",
      productionType: this.productionType || ProductionType.default,
      techAssetId: this.techAssetId || null,
      trailerAssetId: this.trailerAssetId === null ? "-1" : this.trailerAssetId || "",
      employeeId: this.employeeId || "",
      massCleanedProduct: this.massCleanedProduct || 0,
      productivitySet: this.productivitySet,
      params:
        this.TechOperationValues?.reduce((acc, item) => {
          acc[`operationParamId-${item.operationParamId}`] = item;
          return acc;
        }, {}) || {},
      chemicalsMAE: this.materiallyAccountableEmployees.chemicals,
      seedsMAE: this.materiallyAccountableEmployees.seeds,
      fertilisersMAE: this.materiallyAccountableEmployees.fertilisers,
      assets: this.Asset?.map((asset) => asset.asFormData) || [],
    };
  }

  // only used in onSubmit() to create a DTO for backend to add/update techop
  updateFromFormData(data: ITechOperationFormData): void {
    if (!data.startDate || !data.finishDate) {
      throw new Error("Model cant be updated without dates");
    }

    if (data.fieldSize === null) {
      throw new Error("Model cant be updated without fieldSize");
    }

    const { seedsMAE, chemicalsMAE, fertilisersMAE } = data;

    this.farmId = data.farmId;
    this.seasonId = data.seasonId;
    this.farmLandId = data.farmLandId || "";
    this.cropTypeId = data.cropTypeId;
    // this.cropId = data.cropId;

    this.startDate = new Date(data.startDate.getTime());
    this.finishDate = new Date(data.finishDate.getTime());
    this.techOperationGroupId = data.techOperationGroupId || "";
    this.techOperationSubGroupId = data.techOperationSubGroupId || "";
    this.fieldSize = Number(data.fieldSize);
    this.operationNumber = Number(data.operationNumber);
    this.comment = data.comment;
    // commented out to avoid backend returning 204 "NO CONTENT" (??) after RHFv5=>6 upgrade in develop branch
    // this.productionType = data.productionType;
    this.techAssetId = data.techAssetId || null;
    this.trailerAssetId = data.trailerAssetId === "-1" ? null : data.trailerAssetId;
    this.employeeId = data.employeeId;

    // commented out to avoid backend returning 204 "NO CONTENT" (??) after RHFv5=>6 upgrade in develop branch
    // this.massCleanedProduct = data.massCleanedProduct;
    // this.productivitySet = data.productivitySet;

    // techOpValues enriched to mimic onSubmit() in release branch (not failing there)
    // ...to avoid backend returning 204 "NO CONTENT" (??) after RHFv5=>6 upgrade in develop branch
    // this.TechOperationValues = data.params ? Object.values(data.params) : [];
    // let techOpValuesEnriched: ITechOperationValue[] = [];
    // if (data.params) {
    //   const params: ITechOperationValue[] = Object.values(data.params);
    //   if (params.length > 0) {
    //     techOpValuesEnriched = params.map((x) => ({
    //       ...x,
    //       farmId: data.farmId,
    //       techOperationId: data.id,
    //     }));
    //   }
    // }
    // this.TechOperationValues = techOpValuesEnriched;
    this.TechOperationValues = Object.keys(data.params).map((operationParamId) => {
      const value = data.params[operationParamId];
      return TechOperationValue.fromDto2(value, operationParamId, data.id, data.farmId);
    });

    this.materiallyAccountableEmployees = {
      seeds: seedsMAE,
      chemicals: chemicalsMAE,
      fertilisers: fertilisersMAE,
    };

    // WARNING!! when UPDATING TECHOP with this.Asset=[],
    // Loopback backend will remove all assets from TechOperation
    this.Asset = [];
    if (data.assets) {
      // RHF-controlled formTechOp.assets contains EMPTY array elements
      // after I click on basket icon in asset line on UI
      const existingAssets = data.assets.filter((x) => !!x); // !null && !undefined
      this.Asset = existingAssets.map((assetRhf) => {
        const assetModel = new TechOperationAsset(assetRhf.id);
        assetModel.updateFromFormData(assetRhf);
        return assetModel;
      });
    }
  }

  setAppUserId(appUserId: string): void {
    this.appUserId = appUserId;
  }

  setFarmLandId(farmLandId: string | null): TechOperation {
    if (farmLandId) {
      this.farmLandId = farmLandId;
    }
    return this;
  }

  updateEachAsset(farmId: string, techOpId: string): void {
    for (const asset of this.Asset) {
      // to avoid DUPLICATES of "new TechOperationAsset(assetRhf._dtoId).updateFromFormData(assetRhf)",
      // we change fields in an existing object;
      // we don't create new asset[] with new instances inside
      asset.setFarmId(farmId);
      asset.setTechOperationId(techOpId);
    }
  }

  resetCreatedOnClientAt(): void {
    this.createdOnClientAt = format(new Date(), ISO8601_DATETIME_FORMAT);
  }
}
