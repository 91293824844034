import { format as formatDate } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { ISO8601_DATETIME_FORMAT, ISO8601_DATE_FORMAT } from "@constants";
import { IObservationPhotoDtoPicked } from "../dtos/observation-photo.dto";
import { IObservationDto } from "../dtos/observations.dto";
import { IViolationDto } from "../dtos/violation.dto";

export class Observation {
  readonly id: string;
  appUserId: string;
  appUserFullName: string;
  farmId: string;
  farmName: string;
  farmLandId: string;
  farmLandName: string;
  phenoPhaseId: string | null;
  phenoPhaseName: string;
  cropTypeId: string;
  seasonId: string;
  reportNumber: number;
  comment: string;
  violations: IViolationDto[];
  observationPhotos: IObservationPhotoDtoPicked[];
  isReportComplete = false;
  cropId: string;
  cropName: string;
  departmentId: string;
  createdOnClientAt: string | null;
  observationDate = new Date();
  updatedAt?: number; // unixtime
  isDeleted: boolean;

  get observationDateHuman(): string {
    return formatDate(this.observationDate, ISO8601_DATE_FORMAT);
  }

  get editDateHuman(): string {
    return this.updatedAt ? formatDate(this.updatedAt, ISO8601_DATETIME_FORMAT) : "-";
  }

  get asDto(): IObservationDto {
    return {
      id: this.id,
      farmId: this.farmId,
      farmLandId: this.farmLandId,
      phenoPhaseId: this.phenoPhaseId,
      cropTypeId: this.cropTypeId,
      seasonId: this.seasonId,
      reportNumber: this.reportNumber,
      comment: this.comment,
      violations: this.violations,
      appUser: { fullName: this.appUserFullName },
      crop: { name: this.cropName },
      farm: { name: this.farmName },
      farmLand: { name: this.farmLandName },
      phenoPhase: { name: this.phenoPhaseName },
      isReportComplete: this.isReportComplete,
      appUserId: this.appUserId,
      observationDate: formatDate(this.observationDate, ISO8601_DATE_FORMAT),
      updatedAt: this.updatedAt,
      createdOnClientAt: this.createdOnClientAt,
      isDeleted: this.isDeleted,
      observationPhotos: this.observationPhotos,
      cropId: this.cropId,
      departmentId: this.departmentId,
    };
  }

  static fromDto(dto: IObservationDto): Observation {
    const model = new Observation(dto.id);
    model.updateFromDto(dto);
    return model;
  }

  updateFromDto(dto: IObservationDto): void {
    this.appUserId = dto.appUserId;
    this.farmId = dto.farmId;
    this.farmLandId = dto.farmLandId;
    this.phenoPhaseId = dto.phenoPhaseId || null;
    this.cropTypeId = dto.cropTypeId;
    this.seasonId = dto.seasonId;
    this.reportNumber = dto.reportNumber;
    this.comment = dto.comment;
    this.violations = dto.violations || [];
    this.observationPhotos = dto.observationPhotos || [];
    this.isReportComplete = dto.isReportComplete;
    this.appUserFullName = dto.appUser?.fullName || "";
    this.cropName = dto.crop?.name || "";
    this.farmName = dto.farm?.name || "";
    this.farmLandName = dto.farmLand?.name || "";
    this.phenoPhaseName = dto.phenoPhase?.name || "";
    this.createdOnClientAt = dto.createdOnClientAt;
    this.observationDate = new Date(dto.observationDate);
    this.updatedAt = dto.updatedAt;
    this.isDeleted = dto.isDeleted || false;
    this.cropId = dto.cropId;
    this.departmentId = dto.departmentId;
  }

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  setFarmId(value: string): void {
    this.farmId = value;
  }

  setPhenoPhaseId(value: string | null): void {
    this.phenoPhaseId = value === "" ? null : value;
  }

  setCropTypeId(value: string): void {
    this.cropTypeId = value;
  }

  setSeasonId(value: string): void {
    this.seasonId = value;
  }

  setComment(value: string): void {
    this.comment = value;
  }

  setViolations(value: IViolationDto[]): void {
    this.violations = value;
  }

  setObservationDate(value: Date): void {
    this.observationDate = value;
  }

  completeReport(): void {
    this.isReportComplete = true;
  }

  setAppUserId(appUserId: string): void {
    this.appUserId = appUserId;
  }

  setFarmLandId(farmLandId: string | null): Observation {
    if (farmLandId) {
      this.farmLandId = farmLandId;
    }
    return this;
  }

  resetCreatedOnClientAt(): void {
    this.createdOnClientAt = formatDate(new Date(), ISO8601_DATETIME_FORMAT);
  }
}
