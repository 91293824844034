import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { getInitialDictionariesLoaded } from "../../../modules/info-data/info-data-selectors";
import { loadDictionariesAction } from "../../../reducers";
import { SpinnerCenter } from "../../../shared/components/spinner-center/spinner-center";
import { useAppDispatch } from "../../../store";
import { getAuthState } from "../../store/auth.selector";
import { getCurrentUser, logoutSuccess } from "../../store/auth.slice";

export const ProtectedRoute = ({
  urlWhenUserFetchFailed,
}: {
  urlWhenUserFetchFailed: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoadingUserDto, isAuthenticating, error } =
    useSelector(getAuthState);
  const initialDictionariesLoaded = useSelector(getInitialDictionariesLoaded);
  const dispatch = useAppDispatch();

  const fetchCurrentUserOrNavigateToLogin = useCallback(() => {
    dispatch(getCurrentUser())
      .unwrap()
      .catch(() => {
        dispatch(logoutSuccess);
        navigate(urlWhenUserFetchFailed, { replace: true });
      });
  }, [dispatch, navigate, urlWhenUserFetchFailed]);

  const fetchDictionaries = useCallback(() => {
    if (initialDictionariesLoaded) {
      return; // avoid duplicate network requests
    }
    dispatch(loadDictionariesAction());
  }, [dispatch, initialDictionariesLoaded]);

  // authentication check should be passed before rendering
  useEffect(() => {
    if (isAuthenticated) {
      if (isAuthenticating) {
        return; // avoid duplicate network request
      }
      fetchDictionaries();
    } else {
      if (isLoadingUserDto) {
        return; // avoid duplicate network request
      }
      fetchCurrentUserOrNavigateToLogin();
    }
    // eslint-disable-next-line
  }, [
    isAuthenticated,
    isAuthenticating,
    // TEST_FALLS_INTO_INFINITE_LOOP isLoadingUserDto,
    // TEST_FALLS_INTO_INFINITE_LOOP isLoading,
    dispatch,
    fetchCurrentUserOrNavigateToLogin,
    fetchDictionaries,
  ]);

  const msgLoading = useMemo(() => {
    if (isLoadingUserDto) {
      return "Загружаются пользовательские данные...";
    } else if (isAuthenticating) {
      return "Проверяется пользователь...";
    } else if (!isAuthenticated) {
      return "Ожидание авторизации...";
    } else if (!initialDictionariesLoaded) {
      return `Загружаются справочники...`;
    } else if (error) {
      return `Ошибка: ${error}`;
    } else {
      return undefined;
    }
  }, [
    isLoadingUserDto,
    isAuthenticating,
    isAuthenticated,
    initialDictionariesLoaded,
    error,
  ]);

  if (msgLoading) {
    return <SpinnerCenter msg={msgLoading} />;
  }

  return <Outlet />;
};
