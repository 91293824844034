import { NavLink } from "react-router-dom";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { useHideAssetsWriteOff } from "src/shared/hooks/use-hide-asserts-writeoff";
import { Permissions } from "src/authentication/containers/permissions/permissions";
import { PATHS } from "@constants";
import { SideBarIcon } from "../sidebar-icon/sidebar-icon";
import { MENU_LINKS } from "../../constants";
import {
  bgHighlighted,
  colorWhite,
  settingsLinkStyle,
  sideBarLinksStyle,
} from "../../styles";

export const SidebarLinks = (): JSX.Element => {
  const isAssetUsageRecordsEnabled = useHideAssetsWriteOff();
  return (
    <List style={sideBarLinksStyle}>
      {MENU_LINKS.map(({ roles, link, icon, primaryText }) => {
        if (PATHS.ASSET_USAGE_RECORDS === link && !isAssetUsageRecordsEnabled) {
          return null;
        }
        return (
          <Permissions some={roles} key={icon}>
            <NavLink key={link} to={link} style={settingsLinkStyle(icon)}>
              {({ isActive }) => (
                <ListItem sx={bgHighlighted(isActive)}>
                  <ListItemIcon>
                    <SideBarIcon icon={icon} />
                  </ListItemIcon>
                  <ListItemText primary={primaryText} sx={colorWhite} />
                </ListItem>
              )}
            </NavLink>
          </Permissions>
        );
      })}
    </List>
  );
};
