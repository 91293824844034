import { CropMap } from "../../pages/map-page/shared/services/geojson.service";

export type ArcGisError = {
  code: number;
  details: string[];
  message: string;
};

export function isArcGisError(list: CropMap | ArcGisError): list is ArcGisError {
  return (list as ArcGisError).code !== undefined;
}
