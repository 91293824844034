import { format } from "date-fns";

import { ISO8601_DATE_FORMAT, ISO8601_DATETIME_FORMAT } from "@constants";

export const DefaultSchemaValue = {
  BOOLEAN: false,
  STRING: "",
  NUMBER: 0,
  EMPTY: null,
  ISO8601_DATE_FORMAT: format(new Date(), ISO8601_DATE_FORMAT),
  ISO8601_DATETIME_FORMAT: format(new Date(), ISO8601_DATETIME_FORMAT),
};
